<template>
  <div class="private_container">
    <p>感谢您使用云掌柜的产品和服务。您在使用我们的服务时，我们将按照云掌柜用户隐私政策（以下简称“本政策”）收集、存储、使用及对外提供您的信息。请您在使用我们的服务前仔细阅读并理解本政策全部内容。</p>
    <h2>收集的信息</h2>
    <p>1、您在注册账号时填写的信息，如：手机号码、短信验证码、密码信息。</p>
    <p>2、您在使用服务时上传的信息，如：你的日志行为（账户信息、用户会话、访问时间、访问页面、客户端IP地址信息），以及终端信息（设备型号、操作系统、设备标识、浏览器信息）。</p>
    <h2>信息的使用</h2>
    <p>1、向您提供服务，您的信息可能会用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途；</p>
    <p>2、产品开发或帮助我们评估、改善或设计服务及运营活动；</p>
    <p>3、安全保障，本应用不会向任何无关第三方提供您的个人信息，除非获得您的许可，或联合第三方共同向您提供服务；</p>
    <p>根据法律法规或监管要求向相关部门进行报告。</p>
    <h2>policy</h2>
    <p>Cookie及相关技术的使用</p>
    <p>您访问我们的网站或使用提供的服务时，我们可能通过放置安全的Cookie及相关技术收集您的信息，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。您有权对Cookie的接受程度或者拒绝我们的Cookie。您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的类似数据。但这样做可能会影响您的访问安全以及使用我们提供的服务。</p>
    <h2>信息的共享</h2>
    <p>我们遵循法律法规的规定，对您的信息进行严格保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息：</p>
    <p>1、事先经过您的同意</p>
    <p>2、仅为实现外部处理的目的，包括我们的关联公司、第三方合作伙伴等。在分享您的信息之前，我们会对该第三方收集信息的合法性、正当性、必要性，让他们按照我们的说明、隐私政策以及其他法律法规与监管要求。</p>
    <p>3、随着我们业务的持续发展，当发生合并、收购、资产转让等交易导致向第三方分享您的个人信息时，我们将通过推送通知、公告等形式告知您相关情形，按照法律法规及不低于本政策所要求的标准继续保护或要求新的管理者继续保护您的个人信息。</p>
    <p>4、我们会将所收集到的信息用于大数据分析。例如，我们将收集到的信息用于分析形成不包含任何个人信息的城市热力图或行业洞察报告。我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。</p>
    <h2>披露信息</h2>
    <p>我们可能基于以下目的披露您的信息：</p>
    <p>1、经您事先同意，向第三方披露；</p>
    <p>2、遵守法院判决、裁定或其他法律程序的规定；</p>
    <p>3、遵守相关政府机关或其他法定授权组织的要求；</p>
    <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
    <p>5、为执行相关服务协议或本政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途。</p>
    <h2>注销账号的路径：</h2>
    <p>您可以通过以下方式申请注销云掌柜产品账号，一旦您注销云掌柜账号，将无法以此账号使用云掌柜产品的服务，我们会删除您的个人信息或作匿名化处理。上述注销行为和后果是不可逆的，因此请您谨慎操作，以下方式均可以注销：</p>
    <p>a、 通过登录云掌柜PC端：点击“在线客服”在线联系客服确认注销意愿、客服核实您的身份和相关信息，核实完成后，在后台注销您的账户。</p>
    <p>b、 致电云掌柜客服电话：400-002-1566。确认注销意愿、客服会核实您的身份和相关信息，核实完成后，在后台注销您的账户。</p>
    <p>账号注销处理时效：</p>
    <p>在收到您的账号注销请求后，云掌柜将在3至5个工作日予以处理；</p>
    <h2>信息安全</h2>
    <p>1、我们为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或披露</p>
    <p>2、我们严格遵守法律法规保护用户的通信秘密。</p>
    <p>3、我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。</p>
    <p>4、我们建立专门的管理制度、流程和组织确保信息安全。</p>
    <p>5、若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。</p>
    <h2>本政策的使用及更新</h2>
    <p>本政策适用云掌柜提供的相关产品或服务，包括web、手机客户端等版本产品及服务。如本政策发生更新，我们将以APP推送通知、弹窗提示、发送邮件/短消息或者在我们的官方网站发布公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。变更后的政策内容在公示届满7日起生效，如您在本政策更新生效后继续使用我们的服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。</p>
    <p>本政策发布于2012年3月1号，生效于2012年3月1号，更新于2021年8月23号。</p>
    <p>开发者名称-北京米天下科技有限公司 应用名称-云掌柜。</p>
  </div>
</template>

<script>
export default {
  name: "Private"
}
</script>
<style scoped lang="less">
.private_container{
  height: fit-content;
  background: white;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
}
p{
  font-size: 14px;
  margin: 16px;
}
h2{
  margin: 16px;
}
</style>
